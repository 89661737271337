<script>

import {
  HomeIcon,
  UserIcon,
  KeyIcon,
} from "vue-feather-icons";

import Navbar from "@/components/navbar0";

import {BSpinner, BToast,BToaster} from 'bootstrap-vue'


export default {

  data() {
    return {
      loadingStatus: false,
      input2sql: {
        username: '',
        password: '',
      },

      token_habr: '4c580965dd0b354fb6f821adf963a29c9d0ce77d66af0f88df3b0506f5005094',
      redirect_habr: 'https://skillrank.ru/api/prod/loginHabr',

      clinet_id_hh: 'LCEDG0N75IFPHNMD3A4LP7K3MLAC1PRPK9HG7A5M3NJG7JFQEEM29R3GQICRQ3RO',
    };
  },

  methods: {

    sayHello(){
      console.log('hi')
    },




    loginWithLoading() {

      /*
      const loading = this.$vs.loading({
        target: this.$refs.loginButton,
        scale: '0.6',
        background: '#00967d',
        opacity: 0.5,
        color: '#fff'
      });


      this.$vs.loading({
        container: '#div-with-loading',
        //container: this.$refs.loginButton.$el,
        scale: '0.45',
      });
      */

      this.loadingStatus = true;

      this.axios({
        method:'post',
        url: '' + this.$hostname +'/get-token',
        data: this.input2sql,
      }).then( (res) => {
        //console.log(res.data)
        //loading.close()
        this.loadingStatus = false;
        if (res.data.status === 'success') {
          console.log('res.data = ' + res.data)

          console.log(this.$cookies.keys())
          this.$cookies.set('access_token_skillrank', res.data.access_token);
          this.$cookies.set('username_skillrank', res.data.username);
          this.$cookies.set('authority_skillrank', res.data.authority);
          this.$cookies.set('confirmed_skillrank', res.data.confirmed);
          this.$cookies.set('id_skillrank', res.data.id);
          if (res.data.confirmed) {
            this.$cookies.set('showAccountConfirmAlert',false);
          } else {
            this.$cookies.set('showAccountConfirmAlert',true);
          }

          this.$store.commit('UPDATE_LOGGEDIN_STATE', 1);
          this.$store.commit('UPDATE_TOKEN', res.data.access_token);
          this.$store.commit('UPDATE_USERNAME', res.data.username);
          this.$store.commit('UPDATE_USER_ID', res.data.id);
          this.$store.commit('UPDATE_USER_AUTHORITY', res.data.authority);
          this.$store.commit('Update_justRegistered_State', false);

         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          //this.$vs.loading.close(this.refs.loginButton)
           // loading.close()
          this.$router.push({path:'/vector-search'});
        }
        else if (res.data.status.includes('неверный')){
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          //loading.close()
         // this.$vs.notify({
         //   time: 4000,
         //   title: 'Неверный пароль',
         //   text: res.data.status,
         //   color: 'danger'
         // });

          this.$bvToast.toast(res.data.status, {
            title: `Неверный пароль`,
            variant: 'danger',
            solid: true,
            autoHideDelay: 8000,
          });

          this.noPswdLogin = true;
        }
        else if (res.data.status.includes('активировали')){
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          //loading.close()
          //this.$vs.notify({
          //  time: 8000,
          //  title: 'Активируйте аккаунт',
          //  text: res.data.status,
          //  color: 'danger'
         // });
          this.$bvToast.toast(res.data.status, {
            title: `Активируйте аккаунт`,
            variant: 'danger',
            solid: true,
            autoHideDelay: 8000,
          });
          this.noPswdLogin = true;
        } else {
          console.log(res.data)
          //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          //loading.close()
          this.status = res.data.status
          this.statusFlag = true
          //this.$vs.notify({
          //  time: 4000,
          //  title: 'Ошибка',
          //  text: res.data.status,
          //  color: 'danger'
          //});
          this.$bvToast.toast(res.data.status, {
            title: `Ошибка`,
            variant: 'danger',
            solid: true,
            autoHideDelay: 8000,
          });
          //this.$router.push({path:'/login'})
        }
      }).catch((error) => {
        console.log('error')
        console.log(error)
        this.statusFlag = true
        this.status = 'Попробуйте еще раз.'
        //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        //loading.close();
        this.$router.push({path:'/login'});


        });


    },


    loginHabr(){

      var link = 'https://career.habr.com/integrations/oauth/authorize?client_id='+this.token_habr
      link = link +'&redirect_uri='
      link = link +this.redirect_habr
      link = link +'&response_type=code'
      window.open(link);
    },

    loginHh(){


      var link = 'https://hh.ru/oauth/authorize?response_type=code&client_id='+this.clinet_id_hh

      window.open(link);
    },




  },

  components: {
    BSpinner, BToast,BToaster,
    Navbar,
    HomeIcon,
    UserIcon,
    KeyIcon,
  },
};
</script>

<template>
  <div>


    <b-toast id="my-toast" variant="warning" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Notice!</strong>
          <small class="text-muted mr-2">42 seconds ago</small>
        </div>
      </template>
      This is the content of the toast.
      It is short and to the point.
    </b-toast>

    <!--
    <Navbar />
    -->
    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div>

    <!-- Hero Start -->
    <!--<section class="bg-home bg-circle-gradiant d-flex align-items-center">-->
    <section class="bg-half-170">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-8">
            <div class="card login-page bg-white shadow rounded border-0">
              <div class="card-body">
                <h4 class="card-title text-center">Войти</h4>
                <form class="login-form mt-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Ваш Email <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <user-icon class="fea icon-sm icons"></user-icon>
                          <input
                            type="email"
                            class="form-control pl-5"
                            placeholder="Email"
                            name="email"
                            required=""
                            v-model="input2sql.username"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Пароль <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input
                            type="password"
                            class="form-control pl-5"
                            placeholder="Пароль"
                            required=""
                            v-model="input2sql.password"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="d-flex justify-content-between">

                        <div class="form-group">
                          <!--
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customCheck1"
                              />
                              <label
                                class="custom-control-label"
                                for="customCheck1"
                                >Remember me</label
                              >
                            </div>
                            -->
                        </div>

                        <p class="forgot-pass mb-0">
                          <router-link
                            to="/recover-password"
                            class="text-dark font-weight-bold"
                            >Забыли пароль ?</router-link
                          >
                        </p>
                      </div>
                    </div>
                    <!--<div id="div-with-loading" class="col-lg-12 mb-0 mt-3 vs-con-loading__container"> -->
                    <div class="col-lg-12 mb-0 mt-3">
                      <a  size="xl" class="btn btn-primary btn-block " @click="loginWithLoading">
                        <b-spinner v-if="loadingStatus"></b-spinner> Войти
                      </a>
                    <!--  <a  size="xl" class="btn btn-primary btn-block " @click="$bvToast.show('my-toast')">
                        Войти
                      </a> -->
                      <!--<div class="buy-button" @click="sayHello">
                        <span
                            class="btn btn-primary btn-block"
                        >Войти</span>
                      </div>-->

                    <!--  <vs-button  ref="loginButton" size="xl" type="button" class="btn btn-primary btn-block " @click="loginWithLoading">
                        Войти
                      </vs-button> -->

                    </div>
                    <div class="col-lg-12 mt-4 text-center">
                      <h6>Войдите с помощью учетной записи в других сервисах:</h6>
                      <div class="row">
                        <div class="col-6 mt-3">
                          <a
                            class="btn btn-block btn-light"
                            @click="loginHh"
                            >
                            <img src="min-hh-red.png" width="20" height="20" >
                            <!--<i class="mdi mdi-facebook text-primary"></i>-->
                            HH</a>
                        </div>
                        <!--end col-->

                        <div class="col-6 mt-3">
                          <a
                            class="btn btn-block btn-light"
                            @click="loginHabr"
                            ><img src="careerHabrLogo1.jpg" width="20" height="20" >
                            Career.Habr</a
                          >
                        </div>
                        <!--end col-->
                      </div>
                      <!--end icon-->
                    </div>


                    <div class="col-12 text-center">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2"
                          >Вы не регистрировались на платформе? </small
                        >
                        <router-link
                          to="/signup"
                          class="text-dark font-weight-bold"
                          >Создать аккаунт</router-link
                        >
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!---->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End
    <Switcher />
    -->
  </div>
</template>
